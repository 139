@import 'variables';
@import "fonts";
@import "~clyne-scss-core/reset";
@import "~clyne-scss-core/animations";
html {
    height: 100%;
    @include tablet {
        background: #12173E;
    }
    @include desktop {
        @media screen and (max-width: 1380px), screen and (max-height: 660px) {
            font-size: 9px;
        }
        @media screen and (max-width: 1160px) {
            font-size: 8px;
        }
    }
    @media screen and (max-width: 374px) {
        font-size: 9px;
    }
    @media screen and (max-width: 300px) {
        font-size: 7.5px;
    }
}
body {
    @include tablet {
        min-height: 100%;
    }
    &:not(.np) {
        @include tablet {
            transition: 400ms padding;
        }
        @include tablet-portrait {
            padding: calc(#{rem($mobileHeaderSize)} + env(safe-area-inset-top, 0)) 0 calc(#{rem($mobileFooterSize)} + env(safe-area-inset-bottom, 0));
        }
        @include tablet-landscape {
            padding: 0 calc(#{rem($mobileFooterSize)} + env(safe-area-inset-right, 0)) 0 env(safe-area-inset-left, 0);
        }
    }
}
html, body, #root {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    @include desktop {
        overflow: hidden;
        height: 100%;
    }
    @include tablet {
        align-items: flex-start;
        flex-shrink: 0;
        min-height: 100%;
    }
}
iframe {
    &[style*="position: fixed; top: 0px; left: 0px; width: 100%; height: 100%; border: none; z-index: 2147483647;"] {
        display: none;
    }
}
