@import "src/assets/styles/variables";
.you-are-away-holder {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10000;
    display: flex;
    overflow: hidden;
    background: c(base);
    @include vh100;
    --scrollbar-y-gap: #{rem(6)};
    --scrollbar-y-gap-side: #{rem(12)};
}
.you-are-away {
    margin: auto;
    padding: rem(40) rem(20);
    max-width: 100%;
}
