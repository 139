@import "variables";
@mixin font($name, $path, $weight, $range: null) {
    @font-face {
        font-family: $name;
        src: url('../fonts/#{$path}.woff2#{$cache}') format('woff2'),
        url('../fonts/#{$path}.woff#{$cache}') format('woff');
        font-weight: $weight;
        font-style: normal;
        font-display: swap;
        @if ($range != null) {
            unicode-range: $range;
        }
    }
}
@include font('Red Green TypeFace', 'base/main/regular', 400);
@include font('Red Green TypeFace', 'base/main/medium', 500);
@include font('Red Green TypeFace', 'base/main/bold', 700);
@include font('Red Green TypeFace', 'base/arm/regular', 400, $armRange);
@include font('Red Green TypeFace', 'base/arm/medium', 500, $armRange);
@include font('Red Green TypeFace', 'base/arm/bold', 700, $armRange);
@include font('Red Green Decorative', 'decorative/main/regular', 400);
@include font('Red Green Decorative', 'decorative/arm/regular', 400, $armRange);
@include font('Red Green Decorative', 'decorative/rus/regular', 400, $rusRange);
@include font('Red Green Alternative', 'alternative/main/regular', 400);
@include font('Red Green Alternative', 'alternative/arm/regular', 400, $armRange);
@include font('Red Green Alternative', 'alternative/rus/regular', 400, $rusRange);
@import "../fonts/icomoon/style.css";
