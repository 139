@import "src/assets/styles/variables";
.static-message-holder {
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.static-extra-decor-holder {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
.static-extra {
    width: rem(160);
    height: rem(94);
    margin: 0 0 rem(20);
}
.se-traffic-lights {
    height: 100%;
    width: 100%;
    display: flex;
    transform: rotate(-90deg) translate(50vh, 0);
    animation: 400ms tf-fall ease-in forwards;
    position: relative;
    @keyframes tf-fall {
        75% {
            transform: rotate(-90deg) translate(25%, 0);
        }
        85% {
            transform: rotate(0) translate(10%, -10%);
        }
        95% {
            transform: rotate(-15deg) translate(0, -20%);
        }
        to {
            transform: rotate(0) translate(0, 0);
        }
    }
    > li {
        width: 100%;
        height: 100%;
        background: no-repeat 50% 50% / contain;
        animation: 500ms broken-tf-wing 400ms forwards;
        @keyframes broken-tf-wing {
            to {
                transform: translate(0, var(--translate)) rotate(var(--rotate));
            }
        }
        &:first-child {
            background-image: url("./media/left-part.svg");
            --rotate: -6deg;
            --translate: 1%;
        }
        &:last-child {
            background-image: url("./media/right-part.svg");
            transform: translate(-14%, 0);
            --rotate: -19deg;
            --translate: 2%;
        }
    }
}
.static-decor {
    width: 100%;
    min-width: rem(160);
    min-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    svg {
        width: 100%;
        height: auto;
    }
    small {
        display: block;
        padding: 100% 0 0;
        border-radius: 100%;
        background: #F8581F;
    }
    li {
        min-width: rem(8);
        position: absolute;
        opacity: 0;
        animation: bounceIn 1s 0.1s forwards;
        @for $i from 0 to 5 {
            &:nth-child(#{$i + 1}) {
                animation-delay: #{500 + $i * 100}ms;
                > * {
                    animation-delay: -#{$i * 500}ms;
                }
            }
        }
        &:nth-child(1) {
            right: calc(100% + #{rem(60)});
            bottom: calc(100% + #{rem(24)});
        }
        &:nth-child(2) {
            right: rem(42);
            bottom: calc(100% + #{rem(82)});
            small {
                background: #9BBAFF;
            }
        }
        &:nth-child(3) {
            left: 110%;
            bottom: 45%;
        }
        &:nth-child(4) {
            width: rem(50);
            right: 150%;
            bottom: rem(10);
        }
        &:nth-child(5) {
            width: rem(82);
            bottom: 100%;
            left: 120%;
        }
        > * {
            animation: starDecor 3s infinite;
            @keyframes starDecor {
                from, to {
                    transform: scale(1) translate3d(0, 0, 0) rotate(0deg);
                }
                50% {
                    transform: scale(1) translate3d(0, rem(10), 0) rotate(2deg);
                }
            }
        }
    }
}
.sm-title {
    margin: rem(6) 0;
    color: c(b);
    text-transform: uppercase;
    font: f(50) $fa;
    animation: bounceIn 1s;
    @include mobile {
        font: f(36) $fa;
    }
}
.sm-description {
    font: rem(16) / 1.6 $f;
    color: c(b, 0.7);
    max-width: rem(300);
    opacity: 0;
    animation: bounceIn 1s 0.1s forwards;
    @include mobile {
        font-size: rem(14);
    }
}
