@import "src/assets/styles/variables";
.ps {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    overflow-anchor: none;
    -ms-overflow-style: none;
    touch-action: auto;
    -ms-touch-action: auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: flex-start;
    will-change: scroll-position;
    border-radius: inherit;
    max-height: var(--scrollbar-max-height);
    padding: var(--scrollbar-padding, var(--scrollbar-padding-top)) var(--scrollbar-padding, var(--scrollbar-padding-right)) var(--scrollbar-padding, var(--scrollbar-padding-bottom)) var(--scrollbar-padding, var(--scrollbar-padding-left));
    &.touch {
        overflow: auto;
    }
    &:after {
        content: '';
        display: block;
        width: 100%;
        flex-shrink: 0;
        height: var(--scrollbar-margin-bottom);
    }
    @supports (-ms-overflow-style: none) {
        overflow: auto !important;
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        overflow: auto !important;
    }
}
.ps__rail-x {
    height: rem(8);
    bottom: 0;
    .ps--active-x > & {
        display: block;
    }
}
.ps__rail-y {
    width: rem(8);
    right: 0;
    .ps--active-y > & {
        display: block;
    }
}
.ps__rail-y, .ps__rail-x {
    position: absolute;
    display: none;
    opacity: 0;
    transition: opacity .2s linear;
    .ps:hover > &, .ps--focus > &, .ps--scrolling-y > & {
        opacity: 0.9;
    }
    &:hover, &:focus, &.ps--clicking {
        opacity: 1;
    }
}
.ps__thumb-x, .ps__thumb-y {
    position: absolute;
    transition: width .2s ease-in-out, height .2s ease-in-out;
    &:before {
        content: '';
        position: absolute;
        background-color: c(b, 0.2);
        border-radius: rem(8);
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        transition: background-color .2s linear;
    }
}
.ps__thumb-x {
    height: rem(4);
    bottom: var(--scrollbar-x-gap, 0);
    &:before {
        left: var(--scrollbar-x-gap-side, var(--scrollbar-x-thumb-left, 0));
        right: var(--scrollbar-x-gap-side, var(--scrollbar-x-thumb-right, 0));
    }
    .ps__rail-x:hover > &,
    .ps__rail-x:focus > &,
    .ps__rail-x.ps--clicking & {
        height: rem(8);
        &:before {
            background-color: c(b, 0.4);
        }
    }
}
.ps__thumb-y {
    width: rem(4);
    right: var(--scrollbar-y-gap, 0);
    &:before {
        top: var(--scrollbar-y-gap-side, var(--scrollbar-y-thumb-top, 0));
        bottom: var(--scrollbar-y-gap-side, var(--scrollbar-margin-bottom, var(--scrollbar-y-thumb-bottom, 0)));
    }
    .ps__rail-y:hover > &,
    .ps__rail-y:focus > &,
    .ps__rail-y.ps--clicking & {
        width: rem(8);
        &:before {
            background-color: c(b, 0.4);
        }
    }
}
